<template>
  <el-empty description="没有找到内容或权限不够哦！" />
</template>

<script setup>

</script>

<style scoped lang="scss">

</style>
