<template>
  <!--  <button @click="handleLogin">Set Token</button>-->
  <div class="login-body">
    <transition name="fade-scale">
  <div class="formContainer" v-if="show">
    <h3>
      <el-row type="flex" justify="space-between">
        <el-col>
          <el-button-group>
            <el-button class="focusing">登录</el-button>
            <el-button @click="$router.push('/register')">注册</el-button>

          </el-button-group>
                 </el-col>
      </el-row>
    </h3>
    <el-form
        ref="loginFormRef"
        :model="loginForm"
        style="max-width: 600px"
        status-icon
        :rules="loginRules"
        label-width="auto"
        class="loginForm"
    >
      <el-form-item label="用户" prop="username">
        <el-input @keyup.enter="focusNext" ref="firstInput" type="text" autocomplete="true" v-model="loginForm.username"/>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <!--        @keyup.enter="submitForm"-->
        <el-input @keyup.enter="submitForm" ref="secondInput"  type="password" autocomplete="off" v-model="loginForm.password"/>
      </el-form-item>
      <el-form-item :style="{'text-align':'right','margin':'0 ,auto'}">
        <el-button  type="success" plain  @click="submitForm">Submit</el-button>
      </el-form-item>
<!--      <p>登录协议</p>-->
<!--      <span>？忘记密码</span>-->
    </el-form>
  </div>
    </transition>
  </div>
</template>

<script setup>
// const handleLogin = () => {
//   localStorage.setItem('token', '123456')
// }
import {onMounted, reactive, ref, registerRuntimeCompiler} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import axios from "axios";
const store = useStore();
const show = ref(false);
//表单绑定的响应式对象
const loginForm = reactive({
  username: '',
  password: ''
})
const loginFormRef = ref();
const firstInput = ref(null);
const secondInput = ref(null);
//监听组件的挂载 获取焦点
onMounted(() => {
  // 可选，根据需要在组件挂载后立即聚焦
  show.value = true;
});
function focusNext() {
  if (firstInput.value && secondInput.value) {
    firstInput.value.blur();
    secondInput.value.focus();
  }
}
//表单提交的校验规则
const loginRules = reactive(
    {
      username: [
        { required: true, message: '请输入用户名', trigger: 'blur' },
        { min: 2, max: 20, message: '用户名至少两位', trigger: 'blur' }
      ],
      password: [
        { required: true, message: '请输入密码', trigger: 'blur' },
        { min: 6, max: 10, message: 'Length should be 6 to 10', trigger: 'blur' }
      ]
    }
)
// 提交表单函数
//生成router对象
const router = useRouter();
const submitForm = () => {
  loginFormRef.value.validate((valid) => {
    if (valid) {
      // 拿到表单提交后台
      // alert('submit!');
      // console.log(loginForm);
      // localStorage.setItem('token', '123456');
      axios.post('/smartapi/user/login',loginForm).then(res=>{
        // console.log(res.data);
        if(res.data.ActionType === "OK"){
          console.log(res.data.data);
          store.commit('changeUserInfo',res.data.data)
          // store.commit('changeGetterRouter',false)
          router.push('/');
          //由于在前端axios.config.js拦截其中设置了token，所以这里不需要手动设置token
          localStorage.setItem('token',"guozi");
          ElMessage.success({
            message: '登录成功',
            type: 'success',
            duration: 2000
          });
        }
        else{
            ElMessage.error('用户名和密码不匹配哦')
        }
      })
//
//
//     } else {
//       console.log('error submit!!');
//       return false;
    }
  });
};
const register = () => {
  router.push('/register');
}
</script>
<style scoped lang="scss">
/* 定义动画的进入和离开过程 */
.fade-scale-enter-active,
.fade-scale-leave-active {
  transition: opacity 2s, transform 2s;
}

/* 进入结束状态 */
.fade-scale-enter-to,
.fade-scale-leave {
  opacity: 1;
  transform: scale(1);
}

/* 进入初始状态和离开结束状态 */
.fade-scale-enter-from,
.fade-scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
.login-body{
  width: 100%;
  height:calc(100vh - 66px);
  background-color: #E4EBF5;
}
.formContainer{
  width: 350px;
  height: 300px;
  position: fixed;
  border-radius: 10px;
  background-color: #E4EBF5;
  //border: 1px solid rgb(200, 200, 200);
  box-shadow:  10px 10px 20px #c2c8d0,
  -2px -10px 20px #ffffff;
  padding: 10px;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
}
.el-form{
  margin-left: 20px;
}
.el-form-item{
  width: 85%;
}
h3{
  text-align: center;
  margin: 20px 0;
  font-size: 30px;
  color: rgba(0, 79, 47, 0.4);
}
::v-deep(.el-form-item__content){
  display: flex;
  justify-content: center;
  align-items: center;
}
.focusing {
  background-color: var(--el-button-hover-bg-color);
  border-color: var(--el-button-hover-border-color);
  color: var(--el-button-hover-text-color);
  outline: none;
}
</style>
