<template>
  <div class="home">
    <el-row :gutter="20" >
      <el-col class="info-person" :span="6" :xs="24">
        <el-card shadow="hover">
          <div class="avatar">
            <img :src="info.logo?info.logo:'https://img.yzcdn.cn/vant/cat.jpeg'" alt=""/>
            <h3 :style="{color:'rgba(0, 79, 47, 0.4)','font-size':'20px'}">{{ info.nickname }}</h3>
            <p>{{ info.motto }}</p>
            <p>
            <span class="tag-info" v-for="(item,index) in info.tagArr" :key="index" >
           <el-tag ref="tagInput" :type="getTagType(item)">{{item}}</el-tag>
            </span>
            </p>
          </div>

          <p></p>

        </el-card>
        <el-card shadow="hover">
          <template #header>
            <div class="card-header">
              <span><el-tag type="primary">开源项目</el-tag></span>
            </div>
          </template>
          <p
              :style="{ 'text-align': 'left', 'margin-left': '40px' }"
              v-for="(item, index) in openProject"
              :key="index"
              class="text item"
          >
            {{ item.pid}} -
<!--            <el-badge-->
<!--                v-if="index === 0"-->
<!--                :offset="[8, 0]"-->
<!--                value="hot"-->
<!--                class="item"-->
<!--            >-->
              <a @click="toProject(item.project_url)">{{ item.project_name }}</a>
<!--              <a @click="toProject(`www.baidu.com`)">{{ item.project_name }}</a>-->
<!--            </el-badge>-->
<!--            <a v-else>{{ item.project }}</a>-->
          </p>
          <template #footer><p :style="{'font-size':'10px','text-align':'left'}">Copyright © 2024
            <a href="" :style="{'font-size':'10px','text-decoration':'dotted'}">pzh </a>
            All Rights Reserved.</p></template>
        </el-card>
        <el-card shadow="hover" id="contact-info">
          <template #header>
            <div class="card-header">
              <span><el-tag type="warning">个人信息</el-tag></span>
            </div>
          </template>
          <p class="contact-info"> <b style="color: #20b2aa">email:</b>  <span ref="copyTargetEmail" @click="handleCopy(1)">{{ info.email }}</span></p>
          <p class="contact-info" ><b style="color: #20b2aa">wechat:</b> <span  ref="copyTargetWechat" @click="handleCopy(0)">{{ info.wechat }}</span></p>
          <p></p>
          <template #footer></template>

        </el-card>
      </el-col>

      <el-col class="info-detail" :span="18" :xs="24">
        <el-card shadow="hover" v-for="(project,index) in PersonalProjectData" :key="index" >
          <template #header>
            <div class="card-header">
              <span><el-tag :type="getTagType(project.tag)">{{ project.tag }}</el-tag></span>
            </div>
          </template>
<!--          <p>v-model="activeNames" </p>-->
          <h4 :style="{'margin':'10px'}">
            <a :style="{'color':'black'}" href="">
              {{ project.title }}</a>&nbsp;&nbsp;&nbsp;
            <el-rate
                @change="handleChangeStar"
                v-model="starValue"
                :texts="['oops', 'disappointed', 'normal', 'good', 'great']"
                show-text
            />
          </h4>
          <el-collapse>
          <el-collapse-item title="详细介绍:" name="1">
            <div>
              {{ project.info }}
            </div>
            <div>
              {{ project.detail }}
            </div>
            <div>
              {{ project.info_end }}
            </div>
            <div>
              用户、权限管理需要管理员开启权限，如需体验请联系 <a href="" :style="{'font-size':'15px','text-decoration':'dotted'}">站长</a>。
            </div>
          </el-collapse-item>
          </el-collapse>
          <template #footer>
            <el-button-group class="ml-4">
              <el-tooltip content="试试">
              <el-button  :icon="Location" @click="toProject(project.Project_url)"/>
<!--                <a :href="project.project_url">click</a>-->
              </el-tooltip>
              <el-tooltip content="分享">
              <el-button  :icon="Share"  @click="centerDialogVisible = true" />
              </el-tooltip>
              <el-tooltip content="评价">
              <el-button :icon="Comment" @click="commentMe"/>
              </el-tooltip>
            </el-button-group>
          </template>

        </el-card>
        </el-col>

    </el-row>
    <el-dialog v-model="centerDialogVisible" title="tips" width="200px" center>
    <span>
      是否分享内容
    </span>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="centerDialogVisible = false">Cancel</el-button>
          <el-button type="success" @click="shareMessage">
            Share
          </el-button>
        </div>
      </template>
    </el-dialog>


  </div><!--    底部栏-->
  <div class="footer">
    <p>Copyright © 2024 <a href="https://www.haoaiganfan.top">pzh</a> All Rights Reserved.</p>
    <p><a href="https://beian.miit.gov.cn/">赣ICP备2024025527号</a> 技术支持：<a href="http://www.haoaiganfan.top">大头科技网</a></p>

  </div>
</template>

<script setup>
import {computed, h, nextTick, onMounted, reactive, ref} from "vue";
import {ElMessage, ElNotification} from "element-plus";
import {Location, Comment, Share} from '@element-plus/icons-vue'
import axios from "axios";
import store from "@/store";
import { useRouter } from "vue-router";
let router = useRouter()
const centerDialogVisible = ref(false)
const info = ref({
  nickname:'',
  motto:'',
  tag:'',
  project:'',
  email:'',
  wechat:'',
  tagArr:[],
  logo:''
})
const PersonalProjectData = ref({
  pid:'',
  title:'',
  tag:'',
  star:'',
  info:'',
  detail:'',
  info_end:'',
  project_url:'',
})
const openProject = ref([])
onMounted(()=>{
  getPersonalData()
  getPersonalProjectData()
  getOpenProjects()
})
// 获取个人数据
const getPersonalData = async ()=>{

  const res = await axios.get(`/smartapi/personal/list`)

  if (res.data.actionType === "OK"){
    info.value = res.data.data
    // //对象转数组
    // info.value = personalData.value
    //提取标签数组
    info.value.tagArr = info.value.tag.split(',')

    // Object.keys(personalData.value).forEach(item=>{
    //
    // })
  }else{
    ElMessage({
      message: res.data.message,
      type: 'danger',
      duration: 1000,
    })
  }

}
let starValue = ref(4);
const getPersonalProjectData = async ()=> {

  const res = await axios.get(`/smartapi/personal/projectlist`)

  if (res.data.actionType === "OK") {
    PersonalProjectData.value =res.data.data
    // console.log(res.data.data)
  }
}

const getOpenProjects  = async ()=>{
  const res = await axios.get(`/smartapi/personal/openprojects`)
  // console.log(res.data.data)
    openProject.value = res.data.data
}
const toProject = url =>{
  console.log(url)
  // console.log(detailUrl)
  ElMessage.success({
    message: '正在加速前往',
    type: 'success',
    duration: 1000,
    onClose: () => {
      if(!url){
        ElMessage.error({
          message: '该功项目即将上线,请耐心等待',
          type: 'error',
          duration: 1000,
        })
        return
      }
      // try {
      //   window.open(url, '_blank');
      //   return;
      // }catch (e){
      //   //如果跳转失败提示信息
      //     console.log(e,'error window href')
      // }
      window.location.href = url

    }
  })

}

// 定义个人状态标签颜色
const getTagType=(item)=> {
  const tagColors = store.state.tagColor; // 颜色主题数组

  if (item === '学习分享') return 'primary';
  if ((item === '个人博客')||(item === '个人动态')) return 'success';
  if ((item === '最近动态')||(item === '正在构建')) return 'warning';
  if (item === '开心') return 'danger';
  // 当item不匹配预定义的标签时，随机选择一个颜色
  return tagColors[Math.floor(Math.random() * tagColors.length)];
}

// 定义响应式数据
const copyTargetEmail = ref(null);
const copyTargetWechat = ref(null);

// 定义复制方法
const handleCopy = async (Target) => {
  try {
    // 判断复制的是邮箱还是微信
    await navigator.clipboard.writeText(Target?copyTargetEmail.value.innerText:copyTargetWechat.value.innerText);
    // 确保DOM更新后再弹出提示
    await nextTick();
    ElMessage({
      message: '复制成功',
      type: 'success',
      duration: 1000,
    })
  } catch (err) {
    ElMessage({
      message: '复制失败',
      type: 'danger',
      duration: 1000,
    })
  }
};
// 星级评价
const handleChangeStar = (value) => {
  value > 2 ? ElNotification.success({
    // title: 'Success',

        message: h('i', { style: 'color: teal' }, '感谢您的支持!'),
        duration: 1000,}) :
      ElNotification.warning({
        message: h('i', { style: 'color: teal' }, '将会继续努力!'),
        duration: 1000,
      })

  console.log(value);
  starValue.value = value;
};
// 分享内容
const shareMessage = async () => {
  try{
    await navigator.clipboard.writeText(`www.haoaiganfan.top`);
    // 确保DOM更新后再弹出提示
    await nextTick();
    ElMessage.success({
      message: '成功复制到剪切板',
      type: 'success',
      duration: 1000,
    })
  }catch (err){
    ElMessage.error({
      message: '复制失败',
      type: 'danger',
      duration: 1000,
    })
  }

  centerDialogVisible.value = false
}
const commentMe = ()=>{
  if(Object.keys(store.state.userInfo).length === 0){
    ElMessage.error({
      message: '请先登录',
      type: 'danger',
      duration: 1000,
    })
    router.push('/login')
    return
  }
  router.push('/contact')
}
</script>

<style scoped lang="scss">
.tag-info{
    display: inline;
    text-align: center;
    margin: 5px;
}
.tag-info .el-tag{
  margin-top: 5px;
}
.el-card{
  margin: 10px;
}
.el-card a{
  color: #a8acb8;
  font-size: 16px;
  text-decoration: none;
}
.el-card a:hover{
  font-weight: bold;
  color: rgba(0, 79, 47, 0.75);
}
.el-card p, h3{
  text-align: center;
  margin-top: 10px;
  color: #a8acb8;
  font-size: 14px;
}
.el-card h3:hover{
  color: rgba(0, 79, 47, 0.75) !important;
  cursor: pointer;
}
.el-card__body{
  margin-top: 30px !important;
}
.el-card__body .avatar img{
  width: 80px;
  height: 80px;
  border: 1px dotted #a8acb8;
  border-radius: 15px;
  //水平垂直居中
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.contact-info{
  text-align: left !important;
  margin-left: 20px !important;
  cursor: pointer !important;
}
.contact-info span:hover{
  color: rgba(0, 79, 47, 0.8);
  //下划线
  text-decoration: underline;

}
.footer{
  margin-top: 20px;
  height: 50px;
  text-align: center;
  //display允许换行

}
.footer p{
  color: rgb(198, 199, 201);
  font-size: 12px;
}
.footer p a{
  color: #d5d4d4;
  text-decoration: none;
}
.footer p a:hover{
  color: #72B1AE;
  text-decoration:1px solid #72B1AE;
}
//::v-deep(.custom-popover-width) {
//  width: 80px !important; /* 使用!important确保样式优先级 */
//}
@media screen and (max-width: 888px) {

}
@media screen and (max-width: 600px) {
  .card-header{
    text-align: center;
  }
  .el-card{
    width: 95%;
    margin: 10px auto;
  }
  .el-row{
    display: block;
    //flex-basis: 100% !important; /* 设置每一栏占据100%宽度 */
  }
  //::v-deep(.info-person){
  //  width: 100% !important;;
  //}
  //::v-deep(.info-detail){
  //  width: 100% !important;
  //}

}


</style>
