import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
export default createStore({
  state: {
    sideMenuVisible: false,
    // 用户信息
    userInfo:{},
    //标签颜色
    tagColor:['info','success','warning','danger','primary']
  },
  getters: {
  },
  mutations: {
    changeSideMenuVisible(state,data) {
      state.sideMenuVisible = data
    },
    // 改变用户信息
    changeUserInfo(state,value){
      state.userInfo = {
        ...state.userInfo,
        ...value
      }
    },
    // 清空用户信息
    clearUserInfo(state){
      state.userInfo = {}
    }

  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState({
    paths: ['userInfo']//控制是否持久化
  })]})
