import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import '@/styles/index.css'
import 'element-plus/dist/index.css'
createApp(App)
    .use(ElementPlus)
    .use(store)
    .use(router)
    .mount('#app')
/**
 * 定义新类 _NewResizeObserver：这个JavaScript代码定义了一个新的类 _NewResizeObserver，
 * 它扩展了原生的 ResizeObserver 类。ResizeObserver 是一个用于监听元素尺寸变化的API。
 * 重写构造函数：通常，ResizeObserver 的构造函数接收一个回调函数，
 * 当观察到的元素尺寸变化时，会调用这个回调。在这个新的构造函数中，我们覆盖了原有的行为。
 * 使用箭头函数和super：super(() => ...) 调用了父类（即 ResizeObserver）的构造函数，
 * 但是我们将原本的回调包装在一个箭头函数里。这个箭头函数不直接调用用户提供的回调，而是：
 * requestAnimationFrame：箭头函数内部，我们使用 window.requestAnimationFrame。
 * 这是一个浏览器提供的API，它的作用是在下一次重绘之前执行指定的回调函数，确保在渲染新帧时执行，以避免阻塞主线程。
 * 回调的异步调用：requestAnimationFrame 中的回调函数是一个匿名箭头函数，
 * 它调用了用户提供的回调函数 callback 使用 apply 方法。apply 使得 callback 在 _NewResizeObserver
 * 实例的上下文中运行（this 指向实例），并且传递 arguments 对象，这样所有传递给构造函数的参数都可以传递给 callback。
 * 总结：_NewResizeObserver 类在元素尺寸改变时，不会立即执行回调，
 * 而是等到浏览器下一次重绘时异步执行，这有助于优化性能，避免频繁调用造成的卡顿。
 * @type {Window._NewResizeObserver}
 */
window.ResizeObserver = class _NewResizeObserver extends ResizeObserver {
    constructor(callback) {
        super(() => window.requestAnimationFrame(() => callback.apply(this, arguments)));
    }
}
