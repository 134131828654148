// 项目文件: formatTime.js
// 目录路径:src/util
// 创建时间:2024/4/2 20:18
// 创建者:彭振辉--
import moment from "moment";
moment.locale("zh-cn");//语言
const formatTime = {
    getTime:(data)=> {
        return moment(data).format('YYYY/MM/DD');
    }
}

export default formatTime
// 利用库 https://momentjs.cn/
//npm install moment --save
