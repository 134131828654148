import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'
import Info from "@/views/Info.vue";
import Search from "@/views/Search/Search.vue";
import NotFound from "@/views/NotFound/NotFound.vue";
import Login from "@/views/User/Login.vue";
import Register from "@/views/User/Reigster.vue";
import store from "@/store";
import Tool from "@/views/toolKit/Tool.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/info',
    name: 'info',
    component: Info
  },
  {
    path: '/search',
    name: 'search',
    component: Search
  },
  {
    path:'/tool',
    name:'tool',
    component:Tool
  },
  {
    path:'/login',
    name:'login',
    component:Login
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component:NotFound
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  // history:createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // 根据你的实际状态管理方式调整
  // 首次加载不刷新
    store.commit('changeSideMenuVisible', false);
    // 页面自动失去焦点
    next();

});
export default router
