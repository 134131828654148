<template>
  <div class="login-body">
  <div class="register">
    <h3>
      <el-row type="flex" justify="space-between">
        <el-col>
          <el-button-group>
            <el-button @click="$router.push('/login')">登录</el-button>
            <el-button class="focusing">注册</el-button>

          </el-button-group>
        </el-col>
      </el-row>
    </h3>
    <el-form
        ref="userFormRef"
        style="max-width: 600px"
        :model="userForm"
        :rules="userFormRules"
        label-width="auto"
        class="userFrom"
        status-icon
    >
      <el-form-item label="用户名" prop="username">
        <el-input ref="usernameValue" v-model="userForm.username" @change="verify"/>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input v-model="userForm.password" type="password"/>
      </el-form-item>
      <el-form-item label="性别" prop="gender">
        <el-select
            v-model="userForm.gender"
            placeholder="Select"
            style="width: 100%">
          <el-option
              v-for="item in genderOptions"
              :value="item.value"
              :key="item.value"
              :label="item.label"/>
        </el-select>
      </el-form-item>
      <el-form-item label="个人简介" prop="introduction">
        <el-input type="textarea" v-model="userForm.introduction"/>
      </el-form-item>
      <el-form-item>
<!--        insertForm()-->
        <el-button style="margin: 0 auto"  type="primary" plain
                   @click="showVerification" >注册</el-button>
      </el-form-item>
    </el-form>
  </div>
<!--    isVerifying-->

    <!-- 黑色遮罩层 showOverlay -->
    <div class="overlay" v-if="showOverlay">
      <ElSlideVerify
          v-if="isVerifying"
          text="SmartCk提示您请拖动滑块"
          @success="handleVerifySuccess"
          @fail="handleVerifyFail"
      />
    </div>
  </div>
</template>

<script setup>
import {ref, reactive, onMounted} from "vue";
import { useRouter } from "vue-router";
import {ElMessage} from "element-plus";
import axios from "axios";
const usernameValue = ref(null);
const userFormRef = ref();
const userForm = reactive({
  username: '',
  password: '',
  gender: 0,//0 保密 1 男 2 女
  role: 2,//1 管理员 2 普通用户
  introduction: '',
  avatar: '',
  file: null
});
//表单验证
const userFormRules = reactive({
  username: [
    {required: true, message: '请输入用户名', trigger: 'blur'}
  ],
  password: [
    {required: true, message: '请输入密码', trigger: 'blur'},
    {min: 3, max: 6, message: '长度在 3 到 6 个字符', trigger: 'blur'}
  ],
  role: [
    {required: true, message: '请选择权限', trigger: 'blur'}
  ],
  introduction: [
    {required: true, message: '请输入简介', trigger: 'blur'}
  ]
})
// 性别选择
const genderOptions = [
  {
    label: '保密',
    value: 0
  },
  {
    label: '男',
    value: 1
  },
  {
    label: '女',
    value: 2
  }
]

const router = useRouter()

//验证用户名
const verify = () => {
  // console.log(userForm.username)
    axios.get(`/smartapi/user/verify/${userForm.username}`).then(res => {
      // console.log(res.data)
    if (res.data.actionType === 'FALL') {
      ElMessage.warning({
        message: res.data.error,
        type: 'error',
        duration: 2000
      })
      userForm.username = '';
      usernameValue.value.focus()

    } else {
      ElMessage.success({
        message: '用户名可用',
        type: 'success',
        duration: 2000
      })
    }
  })

}
const insertForm = () => {
  userFormRef.value.validate(async (valid)=>{
    if(valid){
      // console.log(userForm)
      // 提交表单
      await axios.post("/smartapi/user/add",userForm).then(res => {
        if (res.data.actionType === 'FALL') {
          ElMessage.warning({
            message: res.data.error,
            type: 'error',
            duration: 2000
          })
        } else {
          ElMessage.success({
            message: '注册成功',
            type: 'success',
            duration: 2000
          })
          router.push(`/login`)
        }
      })

    }else{
      return false
    }
  })
}
import ElSlideVerify from 'vue-slide-verify';
// 滑块显示状态
const isVerifying = ref(false);
// 验证成功值
const verifySuccess = ref(false);
//遮罩
const showOverlay = ref(false);
// 是否加载完成
const isSlideVerifyLoaded = ref(false);// 假设初始时组件未加载
setTimeout(() => {
  isSlideVerifyLoaded.value = true;
}, 4000);
// 点击弹出验证码
const showVerification = ()=> {
  // 首先验证表单
  userFormRef.value.validate(async (valid)=>{
    if(!valid){
      return false
    }
    isVerifying.value = true;
    showOverlay.value = true;
  })
}
// 验证成功
const  handleVerifySuccess = ()=> {
  verifySuccess.value = true;
  // 这里可以发起注册请求或者继续下一步操作
  if (verifySuccess.value) {
    insertForm()
  }
}
const handleVerifyFail = ()=> {
  verifySuccess.value = false;
  isVerifying.value = false;
  showOverlay.value = false;
  // 提示错误信息
  ElMessage.error({
    message: '验证失败',
    type: 'error',
    duration: 2000
  })
}
//
</script>

<style scoped lang="scss">
.login-body{
  position: relative;
  width: 100%;
  height:calc(100vh - 66px);
  background-color: #E4EBF5;
}
.el-form{
  margin-left: 20px;
}
h3{
  text-align: center;
  margin: 20px 0;
  font-size: 30px;
  color: rgba(0, 79, 47, 0.4);
}
.register{
  width: 350px;
  height: 400px;
  position: fixed;
  border-radius: 10px;
  background-color: #E4EBF5;
  //border: 1px solid rgb(200, 200, 200);
  box-shadow:  10px 10px 20px #c2c8d0,
  -2px -10px 20px #ffffff;
  padding: 10px;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
}
.el-form-item{
  width: 85%;
}
.focusing {
  background-color: var(--el-button-hover-bg-color);
  border-color: var(--el-button-hover-border-color);
  color: var(--el-button-hover-text-color);
  outline: none;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* 可调整透明度 */
  z-index:2; /* 确保遮罩层在页面内容之上 */
  display: flex;
  justify-content: center;
  align-items: center;
}
.slide-verify{
  position: absolute;
  background-image: url("@/assets/loading.svg");
  //背景图片居中平铺
  background-repeat: no-repeat;
  background-position: 30% 20%;

  z-index:999;
  transform: translate(0%, -15%);
}
</style>
