<!--  <el-empty description="工具箱正在开发中，敬请期待！" />-->
<template>
  <div id="app">
    <el-row :gutter="20">
      <el-col :span="4" :xs="24" v-for="(item,index) in toolInfo" key="index">
        <el-card  shadow="hover" class="box-card shake-animation" @click="goUrl(item.go_url)">
          <div slot="header" class="clearfix">
            <span class="el-text-success">{{item.url_name}}</span>
<!--            <el-button style="float: right; padding:0px 8px" type="text">go</el-button>-->
          </div>
          <div class="card-info">
            <div class="logo">
              <el-avatar :size="60" src="" @error="errorHandler" shape="square">
                <img :src='item.url_img'/>
              </el-avatar>
            </div>

            <div class="card-info-description">
              <p v-for="(item,index) in item.url_info" key="index">{{item}}</p><br>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>

  </div>

</template>
<script setup>
import {onMounted, ref} from "vue";
import axios from "axios";
const toolInfo = ref([]);
onMounted(()=>{
  getToolInfo()
})
const getToolInfo= async ()=>{
  const res = await axios.get(`/smartapi/tools/list`)
  res.data.data.forEach(item=>{
    item.url_info = item.url_info.split(',')
  })
  // console.log(res.data.data)
  toolInfo.value = res.data.data

}
const goUrl = (url)=>{
  window.open(url)
}
</script>

<style scoped lang="scss">
#app {
  padding: 20px;
  box-sizing: border-box;
}
.el-avatar{
  background-color: transparent !important;
}
.clearfix{
  text-align: center;
}
.el-text-success{
  color: #72B1AE;
  font-size: 25px;
}
.card-info{
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
.card-info .logo{
  width: 30%;
}
.card-info-description{
  color: #99a9bf;
  width: 70%;
  height: 50px;
  line-height: 20px;
  font-size: 12px;
  overflow: hidden;
  //超出部分用省略号替代
  text-overflow: ellipsis;
  padding: 10px;
}
.card-info-description:hover{
  color: #646d7a;
}
.card-info-description p{
  width: 150px;
}
.el-row {

  //& .el-col {
  //  margin-bottom:20px;
  //}
}

.el-col {
  margin-bottom: 10px;
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.shake-animation {
  position: relative;
  cursor: pointer;
  transition: transform 0.3s;
}

.shake-animation:hover {
  animation: shake-animation 0.3s;
}

@keyframes shake-animation {
  0% { transform: translate(1px, 1px); }
  10% { transform: translate(-2px, 0px); }
  20% { transform: translate(2px, 0px); }
  30% { transform: translate(0px, -2px); }
  40% { transform: translate(0px, 2px); }
  50% { transform: translate(-2px, 0px); }
  60% { transform: translate(2px, 0px); }
  70% { transform: translate(-2px, -2px); }
  80% { transform: translate(2px, 2px); }
  90% { transform: translate(-1px, -1px); }
  100% { transform: translate(1px, 1px); }
}
@media screen and (max-width: 768px) {
  .card-info{
    justify-content: start;
  }
  .card-info-description{
    display: block;
    font-size: 18px;
    width: 80%;
    justify-content:start;
  }

}
</style>
