<template>
    <div class="navbar">
      <div class="mask"></div>
      <div class="left">
      <el-menu
          :router="true"
          :default-active="route.fullPath"
          class="el-menu-demo"
          mode="horizontal"
          background-color="#E4EBF5"
          text-color="#72b1ae"
          active-text-color="#20b2aa"

      ><!--          @select="handleSelect"-->
        <el-menu-item :disabled="true"
                      :style="{'color': '#20b2aa',
                      'font-weight':'bold',
                      'font-size':'40px',
                      'font-family':'Edwardian Script ITC,serif'}">
          SmartCk
        </el-menu-item>
        <el-menu-item index="/">Home</el-menu-item>
        <el-menu-item index="/info">tips</el-menu-item>
        <el-menu-item index="/tool">tool</el-menu-item>
<!--        <el-menu-item index="/contact">contact me</el-menu-item>-->
        <el-menu-item v-show="Object.keys(store.state.userInfo).length === 0" index="/login">login</el-menu-item>
      </el-menu>
        <div class="center">

          <div class="search-input">
            <div class="search-logo-small" :style="focus?{'color':'#E1A850'}:{'color':'grey'}"><el-icon><Search /></el-icon></div>
            <input type="text"
                   placeholder="Search"
                   @focus="inputFocus(1)"
                   @blur="inputFocus(0)"
                   @change="ToSearch"
                   v-model="inputSearch"
            >
          </div>
        </div>

      </div>
      <div class="right">
        <div class="menu-logo" @click="controlSideMenu">
          <svg t="1714219779018" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2392" width="16" height="16"><path d="M867.995 459.647h-711.99c-27.921 0-52.353 24.434-52.353 52.353s24.434 52.353 52.353 52.353h711.99c27.921 0 52.353-24.434 52.353-52.353s-24.434-52.353-52.353-52.353z" p-id="2393" fill="#20b2aa"></path><path d="M867.995 763.291h-711.99c-27.921 0-52.353 24.434-52.353 52.353s24.434 52.353 52.353 52.353h711.99c27.921 0 52.353-24.434 52.353-52.353s-24.434-52.353-52.353-52.353z" p-id="2394" fill="#20b2aa"></path><path d="M156.005 260.709h711.99c27.921 0 52.353-24.434 52.353-52.353s-24.434-52.353-52.353-52.353h-711.99c-27.921 0-52.353 24.434-52.353 52.353s24.434 52.353 52.353 52.353z" p-id="2395" fill="#20b2aa"></path></svg>
        </div>
        <transition name="fade">
          <div v-if="showSearch">
            <div v-if="Object.keys(store.state.userInfo).length === 0">
                聪明的鸡腿
            </div>
           <div>{{store.state.userInfo.username}}</div>
          </div>
          <el-input
              v-else
              v-model="inputSearch"
              style="width: 240px"
              placeholder="Please Input"
              @change="ToSearch"
              :prefix-icon="Search">
          </el-input>
        </transition>

        <div class="search-logo" @click="showMenu"><el-icon><Search /></el-icon></div>
        <div class="logout" v-show="Object.keys(store.state.userInfo).length !== 0">
        <el-dropdown :hide-on-click="false">
            <span class="el-dropdown-link">
<!--              <el-icon><User/></el-icon>-->
              <el-icon :size="30" :style="{'color':'#d7948f'}"><HotWater /></el-icon>
            </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="handleLogout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        </div>
      </div>
      </div>

</template>

<script setup>
import {ref} from "vue";
import {  useRoute,useRouter } from "vue-router";
import { useStore } from "vuex";
import { Search, HotWater } from "@element-plus/icons-vue"
import {ElMessage} from "element-plus";
const store = useStore();
const route = useRoute();
const router = useRouter();
const inputSearch = ref('')
const focus = ref(false)
const showSearch = ref(true)
// 搜索标签
const ToSearch = ()=>{
  router.push('/search')
  console.log(inputSearch)
}
const tags = ref([
  { name: '资源', type: 'primary' },
  { name: '简介', type: 'success' },
  { name: '最近', type: 'info' },
  { name: '学习', type: 'warning' },
  { name: '工具', type: 'danger' },
])
const inputFocus = (key)=>{
  focus.value = key !== 0;
}
const showMenu = ()=>{
  showSearch.value = !showSearch.value;
}
// const closeMenu = ()=>{
//   show.value = false;
// }
const controlSideMenu = ()=>{
    store.commit('changeSideMenuVisible',true);
}
const handleLogout = () => {
  // ******************** 退出登录 *********************/
  localStorage.removeItem('token')// 清除token
  store.commit("clearUserInfo")
  ElMessage({
    message: '退出成功哦！',
    duration:2500,
    type: 'warning',
  })
  router.push('/')
  setTimeout(() => {
    window.location.reload()
  }, 500)
}
</script>

<style scoped lang="scss">
//.el-drawer.ttb.open{
//  position: absolute;
//  top:60px;
//}
span{
  border: none;
  outline: none;
}
.navbar{
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 66px;
  background-color: #E4EBF5;
  border-bottom: none;
  overflow: hidden;
  box-shadow:  5px 5px 10px #c6c7c9,
  -5px -5px 10px #ffffff;
}
.el-menu{
  border-bottom: none !important;
}
::v-deep(.el-menu-item) {
  font-weight: bold;
  font-size: 16px;
  margin: 5px;
  margin-left:10px !important;
  border-bottom: none !important;
  margin-top: 2px !important ;
  margin-bottom: 2px !important;
}
::v-deep(.el-menu-item:hover) {
  background-color: #E4EBF5 !important; /* 例如：#6495ED */
}
::v-deep(.el-menu-item:focus) {
  background-color: #E4EBF5 !important; /* 例如：#6495ED */
}
.select-menu{
  position: absolute;
  top: 60px;
  width: 100%;
  height:150px;
  background-color: #E4EBF5;
  transition: ease-out .4s;
}
.search{
  position: fixed;
  top: 30px;
  margin: auto;
}
.menu-list{
  position: absolute;
  top: 50px;
  margin: auto;
  padding-top:40px ;
  padding-left:40px ;
  width: 350px !important;
  height: 70px;
}
//.el-input{
//  box-shadow: inset 9px 9px 18px #f7f7f7,
//  inset -9px -9px 18px #ffffff;
//}
.menu-logo, .search-logo{
  display: none;
}
.is-active {
  //border-radius: 50px;
  border-radius: 8px;
  background: #E4EBF5;
  box-shadow: inset 5px 5px 3px #d2d8e1,
  inset -5px -5px 3px #f6feff;
  transition: ease-out .6s;
}
.right{
  position: fixed;
  top: 0;
  right: 50px;
  width: 100px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color:#20b2aa;

}
.logout{
  //width: 60px;
  position: fixed;
  top: 15px;
  right: 30px ;
  height: 60px;
  line-height: 60px;
  text-align: center;
}
.center{
  position: fixed;
  top: 0;
  right:200px ;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color:#ffffff ;

}
::v-deep(.is-focus){
  border: 1px solid #27adad !important;
}
input{
  line-height: 30px;
  width: 200px;
  height: 35px;
  padding-left: 25px;
  border-radius: 5px;
  background: #E4EBF5;
  border: 1px solid #d2d8e1;
  color:#E1A850 ;
  //box-shadow: inset 5px 5px 10px #d2d8e1,
  //inset -5px -5px 10px #f6feff;
}
.search-input{
  width: 229px;
  position: relative;
}
input:hover{
  //border: 1px solid rgba(0, 79, 47, 0.4);
}
input:focus{
  border: 1px solid #cdd4dd;
  outline:none;
  background: #E4EBF5;
  box-shadow: inset 5px 5px 3px #d2d8e1,
  inset -5px -5px 3px #f6feff;
  transition: ease-out .3s;
}
.search-logo-small{
  color: grey;
  position: absolute;
  top: 3px;
  left: 6px;
}
.menu-s{
  display: none;
}
.close-icon{
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 20px;
}

@media screen and (max-width: 888px) {
  .center{
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .menu-logo, .search-logo{
    width: 100px;
    height: 60px;
    display: block;
  }
  .menu-s{
    display: block;
    z-index: 999;
    border-radius: 0px 0px 10px 10px;
    background: #E4EBF5;
    box-shadow:  10px 10px 20px #c2c8d0;
    //-10px -10px 20px #ffffff;
  }
  .search-logo-small{
    top: 10px;

  }
  .right {
     width: 100%;
    right:0;
     display: inline-flex;
     justify-content: space-between;
     align-items: center;
     margin-left: -20px;
   }
  .logout{
    display: none;
  }
  .left{
    display:none;
    transition: ease-out 3s;
  }
  .history-search{
    margin-top: 10px;
    font-size: 10px;
    color: #20b2aa;
  }
  .mask {
    position: absolute;
    //z-index: 1;
    top: 0;
    left: 20px;
    width: 30px; /* 遮罩宽度 */
    height: 64px;
    background-color: rgba(255, 255, 255, 0.1);
    animation: slideMask 3s alternate-reverse infinite; /* 应用动画 */
  }

  /* 定义关键帧动画 */
  @keyframes slideMask {
    0% {
      transform: translateX(0) skewX(-20deg); /* 初始位置与倾斜角度 */
    }
    50% {
      transform: translateX(400px) skewX(-20deg); /* 移动到底部位置 */
    }
    100% {
      transform: translateX(400px) skewX(-20deg) translateX(100%); /* 移动并隐藏 */
    }
  }

}
</style>
