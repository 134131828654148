<template>
<!--  <el-button @click="visible = true">-->
<!--  Open Drawer with customized header-->
<!--  </el-button>-->
  <el-drawer v-model="store.state.sideMenuVisible"
             :show-close="false"
             direction="ltr"
             size="25%"
  >
    <template #header="{ close, titleId, titleClass }">
      <el-tag :id="titleId" :class="titleClass" @click="close" >Menu</el-tag>
<!--      <el-button type="danger" >-->
<!--        <el-icon size="10" class="el-icon&#45;&#45;left"><CircleCloseFilled /></el-icon>-->
<!--      </el-button>-->


    </template>
<!--@open="handleOpen"
        @close="handleClose"-->
    <el-menu
        default-active="2"
        class="el-menu-vertical-demo"
        :collapse="true"
        active-text-color="#20b2aa"
        :router="true"
        :default-active="route.fullPath"
        :collapse-transition="true"
        :unique-opened="true"
        :popper-offset="6"
    >
      <el-sub-menu index="1">
        <template #title>
          <el-icon><location /></el-icon>
        </template>
          <el-menu-item index="/"><el-icon :style="{'color':'#8ac9ef'}"><Ship /></el-icon>主页</el-menu-item>
          <el-menu-item index="/info"><el-icon :style="{'color':'green'}"><Opportunity /></el-icon>idea</el-menu-item>
          <el-menu-item index="/contact"><el-icon :style="{'color':'#f3c4a9'}"><ChatLineRound /></el-icon>contact</el-menu-item>

      </el-sub-menu>
      <el-sub-menu >
        <template #title><el-icon><User /></el-icon></template>
        <div
            v-show="Object.keys(store.state.userInfo).length === 0"
        >
          <el-menu-item index="/login">登录</el-menu-item>
          <el-menu-item index="/register">注册</el-menu-item>
        </div>
        <div v-show="Object.keys(store.state.userInfo).length !== 0">
          <el-menu-item @click="handleLogout">
            <el-icon :style="{'color':'#d7948f'}"><SwitchButton /></el-icon>
            <span>退出账号</span>
          </el-menu-item>
        </div>

      </el-sub-menu>
      <el-menu-item index="/tool">
        <el-icon><Menu /></el-icon>
      </el-menu-item>
<!--      <el-menu-item index="3" disabled>-->
<!--        <el-icon><Document /></el-icon>-->
<!--      </el-menu-item>-->
      <el-menu-item @click="centerDialogVisible = true">
        <el-icon><Tools /></el-icon>
      </el-menu-item>
    </el-menu>
  </el-drawer>
  <el-dialog v-model="centerDialogVisible" title="tips" width="200px" center>
    <template #header>
      <span>
      站长权限
    </span>
    </template>
    <template #default>
     <p><label>授权码:</label> <input type="password" v-model="rootPassword"@keyup.enter="loginRoot" :size="10"></p>
<!--      <el-form>-->
<!--        <el-form-item label="username">-->
<!--          <el-input v-model="root.username" autocomplete="off"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="password">-->
<!--          <el-input v-model="root.password" autocomplete="off"></el-input>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
    </template>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="centerDialogVisible = false">Cancel</el-button>
        <el-button type="success" @click="loginRoot">
          go to
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { useStore } from "vuex";
import { useRoute, useRouter} from "vue-router";
const store = useStore();
const route = useRoute();
const router = useRouter()
import {ElDrawer, ElMessage} from 'element-plus'
import { Location, Menu, User, Tools, SwitchButton, Opportunity, Ship, ChatLineRound } from '@element-plus/icons-vue'
import {ref} from "vue";
const centerDialogVisible = ref(false)
const rootPassword = ref('')
const loginRoot = () => {
    rootPassword.value === '123456' ?
        ElMessage.success({
          message: 'success',
          type: 'success',
          duration: 1000,
          onClose: () => {
            store.commit('setRoot', true)
            centerDialogVisible.value = false
          }
        }) :
        ElMessage.error({
          message: 'error',
          type: 'error',
          duration: 1000,
          onClose: () => {
            centerDialogVisible.value = false
          }
        })
}
const handleCenter = () => {
  router.push('/')
}
const handleLogout = () => {
  // ******************** 退出登录 *********************/
  localStorage.removeItem('token')// 清除token
  store.commit("clearUserInfo")
  ElMessage({
    message: '退出成功哦！',
    duration:2500,
    type: 'warning',
  })
  router.push('/')
  setTimeout(() => {
    window.location.reload()
  }, 500)
}
</script>

<style scoped lang="scss">
.el-menu {
  border-right: none;
  width: 57.5px;
}
.el-menu-item {
  padding: 0 16px;
}
::v-deep(.el-sub-menu__title){
  padding: 0 16px!important;
}
@media screen and (max-width: 600px) {
  ::v-deep(.el-drawer){
    width:80% !important;
    text-align: right;
  }
}
</style>
